import React from 'react'
import { Link } from 'gatsby'
import { Lightning } from 'phosphor-react'
import { BasicLayout } from '../../layouts/basic'
import { SEOMeta } from '../../components/SEOMeta'
import { GithubSignupBar } from '../../components/auth/GithubSignupBar'
import githubIcon from '../../images/github.svg'
import githubLargeIcon from '../../images/github-large.svg'
import githubLiveTyping from '../../images/github-live-typing.png'
import githubLabels from '../../images/github-labels.png'
import githubConvert from '../../images/github-convert.png'

const Github = ({ location }) => (
  <BasicLayout>
    <SEOMeta
      title="Whiteboard for GitHub"
      description="Work with your GitHub issues and pull requests on a flexible multiplayer whiteboard with two-way sync"
      path={location.pathname}
      image="/qualdesk-github-integration.png"
    />
    <div className="max-w-[720px] mx-auto px-4">
      <header className="mt-8 px-2 text-center">
        <picture className="block mx-auto w-24 h-24 sm:w-32 sm:h-32">
          <source srcset={githubLargeIcon} media="(min-width: 640px)" />
          <img className="w-full h-full" src={githubIcon} alt="GitHub icon" />
        </picture>
        <h1 className="text-text-primary-light mt-4 text-3xl font-extrabold tracking-tight leading-tight sm:text-5xl">
          GitHub
        </h1>
        <p className="text-text-primary-light mt-4 mx-auto max-w-xl text-base sm:mt-6 sm:text-lg">
          Connect to Qualdesk to work with your GitHub issues and PRs on a
          flexible multiplayer whiteboard with two-way syncing.
        </p>
        <a
          className="group bg-background-one-light rounded-[20px] flex items-center mt-8 mx-auto p-1 w-max cursor-pointer sm:mt-10"
          href={process.env.AUTH_GITHUB_URL}
        >
          <div className="to-background-five-light from-background-four-light rounded-[16px] w-[90px] h-[48px] flex items-center justify-center bg-gradient-to-t">
            <Lightning
              className="text-icon-secondary-light group-hover:text-teal-400 w-6 h-6"
              weight="fill"
            />
          </div>
          <div className="text-text-secondary-light w-[90px] h-[48px] flex items-center justify-center text-base font-medium tracking-tight leading-tight">
            Connect
          </div>
        </a>
        <p className="text-text-secondary-light mt-4 text-sm">
          Sign up for Qualdesk using your GitHub credentials
        </p>
      </header>
      <figure className="bg-light-blue-100 relative flex flex-col-reverse justify-between mb-4 mt-12 rounded-xl overflow-hidden sm:mt-14">
        <div className="ring-light-blue-800 absolute inset-0 rounded-xl ring-opacity-20 ring-2 ring-inset" />
        <img
          className="max-w-[476px] max-h-[348px] self-end w-full"
          src={githubLiveTyping}
          alt="Realtime cursors and and live typing on a GitHub issue"
        />
        <figcaption className="text-light-blue-800 p-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
          See your team work on GitHub issues and PRs with realtime cursors and
          live typing
        </figcaption>
      </figure>
      <GithubSignupBar />
      <div className="my-4 sm:grid sm:gap-6 sm:grid-cols-2 sm:my-8">
        <figure className="relative flex flex-col-reverse justify-between my-4 bg-indigo-100 rounded-xl overflow-hidden sm:my-0">
          <div className="absolute inset-0 rounded-xl ring-indigo-800 ring-opacity-20 ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={githubLabels}
            alt="Statuses and assignees on a GitHub Issue"
          />
          <figcaption className="p-8 text-indigo-800 text-2xl font-medium tracking-tight leading-tight">
            Update issue status, assignees and tags directly from the whiteboard
          </figcaption>
        </figure>
        <figure className="bg-amber-100 relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-amber-800 absolute inset-0 rounded-xl ring-opacity-20 ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={githubConvert}
            alt="Sticky note converted to a GitHub Issue"
          />
          <figcaption className="text-amber-800 p-8 text-2xl font-medium tracking-tight leading-tight">
            Transform sticky notes into GitHub issues
          </figcaption>
        </figure>
      </div>
      <footer className="mt-8">
        <Link
          className="border-border-normal-light active:bg-button-secondary-active-light hover:bg-background-three-light text-text-primary-light block px-8 py-4 text-center text-base font-medium tracking-tight leading-tight border rounded-lg"
          to="/integrations"
        >
          View all integrations
        </Link>
      </footer>
    </div>
  </BasicLayout>
)

export default Github
